import React, {useContext, useState} from "react"
import { observer } from 'mobx-react';
import 'mobx-react-lite/batchingForReactDom'
import AuthenticatedLayout from "../components/layouts/authenticatedLayout"
import userService from "../services/user";
import AppAlert from "../components/appAlert";
import ActivationCodes from "../components/activationCodes";
import Card from "react-bootstrap/Card";
import Button from '@material-ui/core/Button';
import {Container, Form} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import alertService from "../services/alert";
import {Alert, AlertType} from "../models/alert";
import mobileInterface from "../services/mobile_interface";
import DownloadSection from "../components/downloadSection";
import {GlobalStateContext} from "../context/GlobalContextProvider";

const LicensePage = () => {

    const user = userService.currentUser;
    const codeRef = React.createRef<HTMLInputElement>();
    const state = useContext(GlobalStateContext);


    async function handleSubmit(event) {
        event.preventDefault();

        if (!codeRef.current?.value)
        {
            alertService.clear('license');
            alertService.push(new Alert("Please enter a code", 'license', AlertType.Error))
            return;
        }

        const code = codeRef.current.value;
        await userService.addActivationCode(code);
    }

    return (
        <AuthenticatedLayout title="Licensing">
            <Container style={{
                "position": "relative"
            }}>
                <h1>License & Options</h1>

                <Card bg='light' className="p-2 mb-5">
                    <Card.Body className="pt-3 pb-2">
                        <AppAlert/>
                        <h2>Register an Activation Code</h2>
                        <p>
                            Add a new code anytime. Just enter the code below and hit the Register button.
                        </p>
                        <Form>
                            <Form.Group controlId="formRegCode">
                                <Form.Control placeholder="Enter activation code" ref={codeRef}/>
                            </Form.Group>
                        </Form>
                        <p>
                            <Button color="secondary" onClick={handleSubmit}>Register</Button>
                            <Button key={"license-button"} className="half-opacity"
                                    href={"https://cmu.flintbox.com/technologies/a8ba9ee5-698a-44c6-bad8-fd02825413f9"} >Licensing</Button>

                        </p>
                    </Card.Body>
                </Card>

                <Card bg='light' className="p-2 mt-5">
                    <Card.Body className="pt-3 pb-2">
                        <h2>Your Activation Codes</h2>
                        <p>
                            Existing codes are shown below with activation and expiration dates.
                        </p>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Expired</th>
                                <th>Code</th>
                                <th>Activated</th>
                                <th>Expires</th>
                            </tr>
                            </thead>
                            <ActivationCodes/>
                        </Table>
                    </Card.Body>
                </Card>


                <Card bg='light' className="p-2 mt-5">
                    <Card.Body className="pt-3 pb-2">
                        <h2>Help</h2>
                        <p>
                            Please contact us with any questions or issues.
                        </p>
                        <p><a target={"_blank"} href={"mailto:info@seventeendays.org"}>info@seventeendays.org</a></p>
                    </Card.Body>
                </Card>
                {state.mobile ? <DownloadSection /> : null}
            </Container>
        </AuthenticatedLayout> )
    };

export default LicensePage
