import React, {useEffect, useState} from "react";
import videosLibrary from "../services/videos";
import mobileInterface from "../services/mobile_interface";
import {Box} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import userService from "../services/user";
import Button from "@material-ui/core/Button";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import DownloadDialog from "./downloadDialog";

const DownloadSection = (props) => {
    const [downloadProgress, setProgress] = useState(0);
    const [isDownloading, setDownloading] = useState(false);
    const [finshedDownloading, setFinished] = useState(false);
    const [allDownloaded, setAllDownloaded] = useState( false);

    const handleVideos = (videoLibraryResponseJSON: string) => {
        const videoLibraryResponse = JSON.parse(videoLibraryResponseJSON);

        console.log("handleVideos() - videoLibraryResponse", videoLibraryResponse);
        console.log("handleVideos() - handleVideos", videosLibrary.videos);
        console.log(Object.keys(videosLibrary.videos).length , Object.keys(videoLibraryResponse).length);
        if(Object.keys(videosLibrary.videos).length == Object.keys(videoLibraryResponse).length) {
            console.log("all downloaded!")
            setAllDownloaded(true);
        }
    }

    if (typeof window !== `undefined`) {
        window.handleVideos = handleVideos;
    }

    useEffect(() => {
        console.log("useEffect getVideosHandler->")
        mobileInterface.PostToMobileInterfaceHandler('getVideosHandler', 'handleVideos', null);
    }, []);
    /* Mobile callback handlers */
    const reportProgress = (numberDownloaded: string) => {
        console.log(numberDownloaded + " of " + Object.keys(videosLibrary.videos).length);
        let percentage = (parseInt(numberDownloaded) / Object.keys(videosLibrary.videos).length) * 100;
        setProgress(percentage);
        if(percentage >= 100){
            setFinished(true);
        }
    }


    const handleDeleteVideos = (success: boolean) => {
        console.log("handleDeleteVideos", success);
        if(success) {
            setAllDownloaded(false);
            setDownloading(false);
            setProgress(0);
            setFinished(false);
        }
    }

    if (typeof window !== `undefined`) {
        window.reportProgress = reportProgress;
        window.handleDeleteVideos = handleDeleteVideos;
    }

    const testApi = (apiEndpoint: string) => {
        if(apiEndpoint == "delete") {
            mobileInterface.deleteVideos();
        }
        if(apiEndpoint == "get") {
            mobileInterface.getVideos();
        }
    }
    if (typeof window !== `undefined`) {
        window.testApi = testApi;
    }
    const downloadVideos = () => {
        const videosJson = JSON.stringify(videosLibrary.videos);
        setDownloading(true);
        console.log(videosJson);
        //mobileInterface.PostToDownloadHandler(videosJson);
        mobileInterface.PostToMobileInterfaceHandler('downloadHandler', 'reportProgress', videosJson);

    }

    const getVideos = () => {
        //mobileInterface.PostToDownloadHandler(videosJson);
        mobileInterface.PostToMobileInterfaceHandler('getVideosHandler', 'handleVideos', null);
    }

    const deleteVideos = () => {
        //mobileInterface.PostToDownloadHandler(videosJson);
        mobileInterface.PostToMobileInterfaceHandler('deleteVideosHandler', 'handleDeleteVideos', null);
    }

    const downloadCard = isDownloading ? <Box display="flex" alignItems="center">
        <Box><CloudDownloadIcon color={"secondary"}/></Box>
        <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" color={"secondary"} value={downloadProgress} />
        </Box>
        <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
                downloadProgress,
            )}%`}</Typography>
        </Box>
    </Box> : null;
    const adminRow = userService.currentUser?.is_admin ? <tr>
        <td>
            <Button
                onClick={()=>getVideos()}
                color={"secondary"}>
                Get TEST
            </Button>
        </td>
        <td>
            <Button
                onClick={() =>deleteVideos()}
                color={"secondary"}>
                Delete
            </Button>
        </td>
    </tr> : null;

    function handleCancel() {
        deleteVideos();
        setDownloading(false);
    }

    const dlBtn = allDownloaded
        ? <Button
                onClick={deleteVideos}
                color={"secondary"}>
                Delete
            </Button>
        : <Button
            disabled={isDownloading}
            onClick={downloadVideos}
            startIcon={<CloudDownloadIcon/>}
            color={"secondary"}>
            Download
        </Button>;
    return (

        <>
            <Card bg='light' className="p-2 mt-5">
                <Card.Body className="pt-3 pb-2">
                    <h2>Options</h2>

                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Option</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>
                                Download videos for low bandwidth
                            </td>
                            <td>
                                {dlBtn}
                            </td>
                        </tr>
                        {adminRow}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            <DownloadDialog
                title="Downloading Videos"
                open={isDownloading}
                setOpen={setDownloading}
                onCancel={() => handleCancel()}
                isFinished={finshedDownloading}

            >
                <p>All videos are being downloaded. This can take a while. Please allow this operation to complete.</p>
                {downloadCard}
            </DownloadDialog>

        </>

    );
}

export default DownloadSection;
