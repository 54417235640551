import React from "react"
import {observer} from "mobx-react";
import userService from "../services/user";

const ActivationCodes = () => {
    // -- 2 --
    const rows = userService.activationCodes.map((item, indx) => {
        const activated_on = new Date(item.activated_on).toLocaleDateString();
        const expires = new Date(item.expires_on).toLocaleDateString();

        return (
            <tr key={item.code}>
                <td>{indx + 1}</td>
                <td>{item.expired ? 'Yes' : 'No'}</td>
                <td>{item.code}</td>
                <td>{activated_on}</td>
                <td>{expires}</td>
            </tr>
        )
    })
    
    return (<tbody>{rows}</tbody>);
};

export default observer(ActivationCodes);