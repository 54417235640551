import React from "react"
import { redirectTo } from "@reach/router"
import userService from "../../services/user";
import {Col, Container, Row} from "react-bootstrap";
import NavbarInContext from "../navBarInContext";
import { navigate } from "gatsby"
import AuthenticatedFullscreenLayout from "./authenticatedFullscreenLayout";
import MainMenu from "../mainMenu";
import CourseHOC from "../../hoc/courseHoc";

type AuthenticatedLayoutProps = {
    children: any,
    title: string
}
class AuthenticatedLayout extends React.Component<AuthenticatedLayoutProps> {

    readonly children: any;
    readonly title: string;
    isLoggedIn: boolean = false;
    private containerStyle: {};

    constructor(props: AuthenticatedLayoutProps)
    {
        super(props);
        this.children = props.children;
        this.title = props.title;
        this.containerStyle = {"width":"100%"};

    }

    componentWillMount() {
        const isBrowser = typeof window !== "undefined"

        if (!userService.isLoggedIn() && isBrowser) {
            navigate("/login/")
        }
    }

    render() {
        
        return (
            <>
                <Container fluid className="px-0 main">
                    <div className={"bgImage"}></div>
                    <div className={"d-flex justify-content-start"} style={{"height":"100vh"}}>
                        <MainMenu/>
                        <div className="px-0 main p-0" style={this.containerStyle}>
                            <div style={{"height":"100vh", "width":"100%","overflow":"scroll" }}>
                                <main className={"w-100 d-flex justify-content-center"} style={{"flexDirection":"column"}}>{this.children}</main>
                            </div>
                        </div>
                    </div>
                </Container>
            </>
        )
    }
}

export default AuthenticatedLayout