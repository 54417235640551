import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {navigate} from "gatsby";
import userService from "../services/user";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const DownloadDialog = (props) => {
    const { title, children, open, setOpen, onCancel } = props;
    const [finshedDownloading, setFinished] = useState(false);

    useEffect(() => {
        console.log("useEffect")
        setFinished(props.isFinished)
    }, [props.isFinished]);
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        onCancel();
                    }}
                    color="secondary"
                >
                    Cancel
                </Button>
                <Button
                    disabled={!finshedDownloading}
                    variant="contained"
                    onClick={() => {
                        setOpen(false);
                    }}
                    color="secondary"
                >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DownloadDialog;